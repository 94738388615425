// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--Yxd7Y";
export var content = "index-module--content--ckdfD";
export var contentHover = "index-module--contentHover--8p-SA";
export var descBg = "index-module--descBg--FzyQU";
export var descContent = "index-module--descContent--59+ro";
export var education = "index-module--education--q0iXO";
export var experienceDemo = "index-module--experienceDemo--ER888";
export var experienceKPI = "index-module--experienceKPI--A3r02";
export var guidance = "index-module--guidance--w-Hn9";
export var guidanceLogo = "index-module--guidanceLogo--pEPCG";
export var hoverShow = "index-module--hoverShow--zS8Aj";
export var hoverUp = "index-module--hover-up--03sYD";
export var position_left = "index-module--position_left--A-U3C";
export var viewData = "index-module--viewData--VAHgB";
export var watchVideo = "index-module--watchVideo--WqbkB";